require("./src/css/style.css");
const React = require("react");
const PropTypes = require("prop-types");
const { ApolloProvider } = require("@apollo/react-hooks");
const ApolloBoost = require('apollo-boost');
const ApolloClient = ApolloBoost.default;
const fetch = require('isomorphic-fetch');
const client = new ApolloClient({
    uri: 'https://resourceserver.de/v1/graphql',
    fetch
});
let wrapRootElement = ({ element }) => {
    const Keycloak = require("keycloak-js");
    const { KeycloakProvider } = require("@react-keycloak/web");

    //TODO: Use ENV VARS (dotenv)
    const keycloak = new Keycloak({
        realm: 'seitenwerke',
        url: 'https://identityserver.de/auth/',
        clientId: 'seitenwerke-frontend'
    });
    return (
        <KeycloakProvider
            keycloak={keycloak}
            initConfig={{
                promiseType: "native",
                onLoad: "check-sso",
                onAuthSuccess: () => {
                    console.log("auth success");
                    client.request = (operation) => {
                    const token = keycloak.token;
                    console.log(token)
                    operation.setContext({
                          headers: token ? {
                              authorization: `Bearer ${token}`
                          } : {}
                     });
                   }
                },
                pkceMethod: 'S256',
                checkLoginIframe: true,
                enableLogging: true,
                silentCheckSsoFallback: false,
                silentCheckSsoRedirectUri:
                    window.location.origin + "/silent-check-sso.xhtml",
            }}
        >
            <ApolloProvider keycloak={keycloak} client={client}>{element}</ApolloProvider>
        </KeycloakProvider>
    )
}
let wrapRootElementSSR = ({ element }) => {
    return (
        <ApolloProvider client={client}>{element}</ApolloProvider>
    )
}

wrapRootElement.propTypes = {
    element: PropTypes.node,
}
wrapRootElementSSR.propTypes = {
    element: PropTypes.node,
}
if (typeof window !== 'undefined') {
    //do nothing
} else {
    wrapRootElement = wrapRootElementSSR;
}
exports.wrapRootElement = wrapRootElement;
